import React, { useEffect, useMemo, useState } from "react";
import TopMenu from "../../../components/TopMenu";

import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Alert,
	Autocomplete,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Modal,
	Slide,
	Snackbar,
	Stack,
	TableHead,
	TableSortLabel,
	Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import HelpButtonInput from "../../../components/HelpButtonInput";
import styles from "./RequestItemDetalheCad.module.css";
//components
import { visuallyHidden } from "@mui/utils";
import InputData from "../../../components/InputData";
import RegisterArea from "../../../components/RegistrationArea";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { FarmService } from "../../../service/farm_service";
import { HarvestService } from "../../../service/harvest_service";
import { OrderService } from "../../../service/order_service";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	height: "70vh",
	maxHeight: "80vh",
	transform: "translate(-50%, -50%)",
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "stretch",
	justifyContent: "stretch",
	widths: "auto",
	m: 0,
	p: 0,
	minHeight: "45px",
	minWidth: "40vw",
};

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"  
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const columnForActiveLote = [
	{
		id: "cropSeason",
		label:"Safra",
	},
	{
		id: "date",
		label:"Data",
	},
	{
		id: "harvest",
		label:"Lote",
	},
	{
		id: "disponible",
		label:"Quantidade disponivel",
	},
];

function RequestsCad() {
	const url = new URLSearchParams(window.location.search);
	const orderId = url.get("orderId");
	const orderItemId = url.get("orderItemId");
	const loteId = url.get("loteId");
	const [orderItem, setOrderItem] = useState({
		propriedadeCultivo: null,
		embalagem: null
	});

	const [allFarms, setAllFarms] = useState([]);
	const [orderNumber, setOrderNumber] = useState("");
	const [openLote, setOpenLote] = useState(false);
	const keysForActiveLote = columnForActiveLote; 
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [openLoteDialog, setOpenLoteDialog] = useState(false); //modal
	const [idRowLote, setIdRowLote] = useState(null);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(keysForActiveLote[0].id);
	const [typeMessage, setTypeMessage] = useState("info");
	const [message, setMessage] = useState("");
	const [openAlertModal, setOpenAlertModal] = useState(false);
	const [errorMessageInputQuantidade, setErrorMessageInputQuantidade] = useState(false);
	const [errorMessageInputCultura, setErrorMessageInputCultura] =	useState(false);
	const [errorMessageInputEmbalagem, setErrorMessageInputEmbalagem] = useState(false);
	const [errorMessageDataEntrega, setErrorMessageDataEntrega] = useState(false);
	const [harvests, setHarvests] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const farmService = new FarmService();
	const harvestService = new HarvestService();
	const orderService = new OrderService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const farms = await getAllFarms(propertyId);

		if(orderId && orderItemId && loteId){
			const order = await orderService.getOrder(orderId);
			const orderitem = await orderService.getOrderItem(orderItemId);
			
			const harvest = orderitem.lotes.find((item) => item.lote.id === loteId);
			const farm = farms.find((farm) => farm.id === harvest.lote.propriedadeCultivoId);
			const farmPackage = farm.embalagens.find((farmPackage) => farmPackage.id === orderitem.embalagemId);
			setOrderNumber(order.numeroPedido);
			setOrderItem({
				propriedadeCultivo: farm,
				propriedadeCultivoId: farm.id,	
				embalagem: farmPackage,
				embalagemId: farmPackage.id,
				preco: orderitem.preco,
				quantidade: orderitem.quantidade_solicitada,
				quantidadeAntiga: orderitem.quantidade_solicitada,
				dataEmbalagem: orderitem.data_embalagem,
				harvest: {
					id: harvest.lote.id,
					codigo: harvest.lote.codigo
				}
			});
		}
		setIsLoading(false);
	};

	const getAllFarms = async (propertyId) => {
		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};
		const farmsInProperty = await farmService.get(farmParams, propertyId);
		
		const farmsToShow = farmsInProperty.map((farm) => {
			const title = captalizeFirstLetterEacheWord(`${farm.cultivo.cultura} ${farm.cultivo?.variedade ?? ""}`);
			const firstChar = title[0].toUpperCase();
			const firstLetter = /[0-9]/.test(firstChar) ? "0-9" : firstChar;

			return {
				id: farm.id,
				title: title,
				firstLetter: firstLetter,
				embalagens: farm.embalagens.map((packageFarm) => {
					return {
						...packageFarm,
						label: `${packageFarm.nome} (${packageFarm.quantidade} ${packageFarm.unidadeMedida.sigla.toUpperCase()})`,
					};
				}),
			};
		});

		setAllFarms(farmsToShow);
		return farmsToShow;
	};

	const validationSave = async () => {
		let message = "Um ou mais campos vazios! - (";
		if (
			orderItem.propriedadeCultivo &&
			orderItem.embalagem &&
			orderItem.dataEmbalagem &&
			orderItem.quantidade
		) {
			setErrorMessageInputCultura(false);
			setErrorMessageInputEmbalagem(false);
			setErrorMessageInputQuantidade(false);
			setErrorMessageDataEntrega(false);
			if (orderItem.harvest) 
				handleSave();
		} else{
			if (!orderItem.propriedadeCultivo) {
				message += "Selecione a cultura, ";
				setErrorMessageInputCultura(true);
			} else {
				setErrorMessageInputCultura(false);
			}
	
			if (!orderItem.embalagem) {
				message += "Selecione a embalagem, ";
				setErrorMessageInputEmbalagem(true);
			} else {
				setErrorMessageInputEmbalagem(false);
			}
	
			if (!orderItem.quantidade) {
				message += "Quantidade, ";
				setErrorMessageInputQuantidade(true);
			} else {
				setErrorMessageInputQuantidade(false);
			}
			if (!orderItem.dataEmbalagem) {
				message += "Data de entrega, ";
				setErrorMessageDataEntrega(true);
			} else {
				setErrorMessageDataEntrega(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
		}
		setOpenAlertModal(true);		

	};
	const handleCloseAlertModal = (event, reason) => {
		if (reason === "clickaway") return;

		setOpenAlertModal(false);
	};

	const handleSave = async () => {
		if(!orderItemId){
			const itemToSave = {
				farmId: orderItem.propriedadeCultivoId,
				packagingId: orderItem.embalagemId,
				lotId: orderItem.harvest.id,
				price: Number(orderItem.preco?.replace(",", ".")),
				totalAmount: Number(orderItem.quantidade.replace(",", ".")),
				packagingDate: new Date(orderItem.dataEmbalagem),

			};
			await orderService.saveItem(orderId, itemToSave);
		} else {
			const itemToUpdate = {
				id: orderItemId,
				farmId: orderItem.propriedadeCultivoId,
				packagingId: orderItem.embalagemId,
				oldLotId: loteId,
				newLotId: orderItem.harvest.id,
				price: orderItem.preco ? Number(orderItem.preco.toString().replace(",", ".")) : orderItem.preco,
				oldTotalAmount: orderItem.quantidade ? Number(orderItem.quantidade.toString().replace(",", ".")) : orderItem.quantidade,
				newTotalAmount: Number(orderItem.quantidadeAntiga),
				packagingDate: new Date(orderItem.dataEmbalagem),
			};
			await orderService.updateItem(orderId, itemToUpdate);
		}
		window.location.href = `/pedidos/detalhes/${orderId}`;
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const getHarvests = async (farmId, packageId) => {
		const propertyId = localStorage.getItem("propertyId");
		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(
				JSON.stringify(
					{ 
						pendente: false, 
						previsao: false,
						propriedadeCultivoId: farmId,
						embalagemId: packageId 
					}
				)
			)
		};

		let propertyHavests = await harvestService.getByProperty(propertyId, params);
		propertyHavests = propertyHavests.harvests;

		const harvestsToShow = [];
		propertyHavests.forEach((propertyHarvest) => {
			harvestsToShow.push({
				id: propertyHarvest.id,
				cropSeason: `${propertyHarvest.propriedadeCultivo.cultivo.cultura} ${propertyHarvest.propriedadeCultivo.cultivo.variedade} - ${formatDate(propertyHarvest.dataColheita)}`,
				harvest: propertyHarvest.codigo,
				date: formatDate(propertyHarvest.dataColheita),
				disponible: 100,
			});
		});
		setHarvests(harvestsToShow);
	};

	const handleOpenLote = () => {
		setOpenLote(true); //abre o modal
	};
	const handleCloseLote = () => {
		setOpenLote(false); //fecha o modal
	};
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	
	const handleOpenLoteDialog = (e) => {
		const item = e.target.id.split("&");
		if (item !== undefined) setIdRowLote(item[0]); 
		setOpenLoteDialog(true); //abre o modal
	};
	
	const handleCloseAddLote = () => {
		const harvest = harvests.find((item) => item.id === idRowLote);
		setOrderItem({...orderItem, harvest: {
			id: harvest.id,
			codigo: harvest.harvest,
		}});

		setOpenLote(false); //fecha o modal
		setOpenLoteDialog(false);
	};
	const handleCloseLoteDialog = () => {
		setOpenLoteDialog(false); //fecha o modal
	};
	
	let dataAllLotes = [];
	
	harvests.map((item, index) => {
		return dataAllLotes.push({
			id: item.id,
			dataColheita: formatDate(item.dataColheita),
			nome: item.nome,
			safra: item.safra,
			propCultura: item.propCultura,
			embalagemId: item.embalagemId,
			index: index,
		});
	});
	// let dataFiltrada = [];
	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(harvests, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, harvests]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "requests"]} help="requestsEdit" />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										border: "none",
										boxShadow: "none",
										display: "flex",
										alignItems: "stretch",
										justifyContent: "stretch",
										widths: "auto",
										m: 0,
										p: 0,
										minHeight: "45px",
										minWidth: "40vw",
									}}
								>
									<RegisterArea
										title={orderItemId !== null?("Editar item do Pedido - " + orderNumber):("Cadastrar item para o pedido")}
										href={"/pedidos/detalhes/" + orderId}
										salveFunc={validationSave}
									>
										<Grid
											container
											rowSpacing={3}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											
											<Grid item xs={12} sm={6} md={6}>
												<	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.propriedadeCultivo ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Cultura/Variedade
													</Typography>
													<div
														className={
															errorMessageInputCultura ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageInputCultura ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<ThemeProvider theme={theme}>
															<Autocomplete
																options={allFarms.sort(
																	(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.title}
																sx={{ width: "100%", border: "none" }}
																value={orderItem.propriedadeCultivo}
																onChange={(event, newValue) => {
																	if(newValue.id !== orderItem.propriedadeCultivoId){
																		setOrderItem({
																			...orderItem,
																			propriedadeCultivoId: newValue.id, 
																			propriedadeCultivo: newValue,
																			embalagem: null,
																			embalagemId: null
																		});
																	}
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			disabled={loteId !== null && loteId !== undefined }
																			placeholder="Selecionar Cultura/Variedade"
																			className={styles.inputRequestItem}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.embalagem ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Embalagem
													</Typography><div
														className={
															errorMessageInputEmbalagem ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageInputEmbalagem ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<ThemeProvider theme={theme}>
															<Autocomplete
																value={orderItem.embalagem}
																onChange={(event, newValue) => {
																	setOrderItem({...orderItem, embalagem: newValue, embalagemId: newValue.id});
																	getHarvests(orderItem.propriedadeCultivoId, newValue.id);
																}}
																options={orderItem.propriedadeCultivo?.embalagens ?? []}
																sx={{ width: "100%", border: "none" }}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecione a embalagem"
																			className={styles.inputRequestItem}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "row",
													alignItems: "center",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.harvest ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Lote
													</Typography>		
													<div
														className={styles.noErrControl}
													>
														<input
															type="text"
															id="lote"
															disabled
															value={orderItem.harvest?.codigo}
															className={styles.inputRequestItem}
														>
														</input>
													</div>
													<button
														onClick={handleOpenLote}
														type="button"
														className={styles.ButtonColher}
													>
															Selecionar lote
													</button>												
												</Box>
											</Grid>
											
											<Grid item xs={12} sm={6} md={6}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.preco ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Preço
													</Typography>											
													<input
														type="number"
														placeholder="Preço"
														value={orderItem.preco}
														onChange={(event) =>
															setOrderItem({...orderItem, preco: event.target.value})
														}
														className={styles.inputRequestItem}
													/>
												</Box>
											</Grid>
											<Grid item xs={9} sm={11} md={3}>			
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>										
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.quantidade ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Quantidade
													</Typography>	
													<div
														className={
															errorMessageInputQuantidade ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageInputQuantidade ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<input
															className={styles.inputRequestItem}
															type="number"
															placeholder="Quantidade"
															value={orderItem.quantidade}
															onChange={(event) =>
																setOrderItem({...orderItem, quantidade: event.target.value})
															}
														/>
													</div>	
												</Box>
											</Grid>
											<Grid
												item
												xs={1}
												sm={1}
												md={1}
												sx={{
													display: "flex",
													justifyContent: "start",
													alignItems: "center",
													marginLeft: "20px",
													marginRight: "-20px",
												}}
											>
												unidade aqui
											</Grid>
											
											<Grid item xs={12} sm={12} md={2}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(orderItem.dataEmbalagem ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Data de embalagem
													</Typography>
													<div
														className={
															errorMessageDataEntrega ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDataEntrega ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData
															classN={styles.inputRequestItem}
															placeholder="Data de embalagem"
															onDateChange={(date) => setOrderItem({...orderItem, dataEmbalagem: date})}
															value={orderItem.dataEmbalagem ? new Date(orderItem.dataEmbalagem).toLocaleDateString("pt-BR") : orderItem.dataEmbalagem}
														/>
													</div>
												</Box>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={openLote}
					onClose={handleCloseLote}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<div className={styles.titleTabelaModal}>
									<h2>
										<b>Lotes</b>
									</h2>
									<HelpButtonInput
										title={"Tabela de Lotes"}
										text={"Clique em cima do item desejado para seleciona-lo."}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Paper sx={{ width: "100%", p: 1, m: 0 }}>
									<ThemeProvider theme={theme}>
										<TableContainer sx={{ maxHeight: "46vh" }}>
											<Table stickyHeader>
												<EnhancedTableHead
													order={order}
													orderBy={orderBy}
													onRequestSort={handleRequestSort}
													rowCount={dataAllLotes.length}
													columns={keysForActiveLote}
												/>
												<TableBody>
													{
														//cria uma linha por objeto
														visibleRows
															.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
															.map((row, index) => {
																return (
																	<TableRow hover tabIndex={-1} key={index}>
																		{keysForActiveLote.map((column) => {
																			return (
																				<TableCell
																					key={column}
																					id={row.id+"&"+row.safra}
																					onClick={handleOpenLoteDialog}
																				>
																					{row[column.id]}
																				</TableCell>
																			);
																		})}
																	</TableRow>
																);
															})
													}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
											component="div"
											colSpan={7}
											count={visibleRows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											labelRowsPerPage="Linhas por pagina"
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: false,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</ThemeProvider>
								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseLote}
													className={styles.ButtonCancelarModal}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
						<Dialog
							open={openLoteDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleCloseLoteDialog}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									Deseja Selecionar esse item?
								</DialogContentText>
							</DialogContent>
							<DialogActions
								sx={{
									display: "flex",

									flexDirection: "row",
								}}
							>
								<button
									className={styles.ButtonCancelarModal}
									onClick={handleCloseLoteDialog}
								>
									Cancelar
								</button>

								<button
									className={styles.ButtonColherModal}
									onClick={handleCloseAddLote}
								>
									Selecionar
								</button>
							</DialogActions>
						</Dialog>
					</Box>
				</Modal>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={openAlertModal}
						autoHideDuration={6000}
						onClose={handleCloseAlertModal}
					>
						<Alert
							onClose={handleCloseAlertModal}
							severity={typeMessage}
							sx={{ width: "100%" }}
						>
							{message}
						</Alert>
					</Snackbar>
				</Stack>
			</div>
		);
}

export default RequestsCad;