import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import * as React from "react";
import Chart from "../../components/Chart";
import Climate from "../../components/Climate";
import HomeCalender from "../../components/HomeCalender";
import TopMenu from "../../components/TopMenu";
import "../CommonStyle/style.css";
import "./style.css";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ManagementService } from "../../service/management_service";
import { HarvestService } from "../../service/harvest_service";
import Loading from "../../components/Loading";
//import ContractModal from "./contractModal.js";

const options = {
	title:
		"Registros de serviços, insumos aplicados e colheitas para os último 3 meses, em unidades.",
	curveType: "function",
	legend: { position: "top" },
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function DrawerAppBar() {
	//const [license, setLicense] = useState(true);
	const [data, setData] = useState([
		[
			"atividade",
			"Serviços Registrados",
			"Insumos Aplicados Registrados",
			"Colheitas Registradas",
		],
		["data", 0, 0, 0],
		["data", 0, 0, 0],
		["data", 0, 0, 0],
	]);
	const [isLoading, setIsLoading] = useState(true);
	const managementService = new ManagementService();
	const harvestService = new HarvestService();

	useEffect(() => {
		//getContractLicense();
		let startDate = getStartDate();
		let finalDate = getFinalDate();
		setDataForPeriod(startDate, finalDate);
	}, []);
	
	const getStartDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;

		let startMonth =
			currentMonth < 4 ? 12 - (2 - currentMonth) : currentMonth - 2;
		return `${
			currentMonth < 4 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
		}-${startMonth.toString().padStart(2, "0")}-01`;
	};

	const getFinalDate = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		let startMonth = currentMonth < 4 ? 12 - currentMonth : currentMonth;

		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentMonth,
			0
		).getDate();

		return `${currentDate.getFullYear()}-${startMonth
			.toString()
			.padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;
	};

	const setDataForPeriod = async (startDate, finalDate) => {
		const propertyId = localStorage.getItem("propertyId");
		let currentDate = new Date();
		let currentMonth = currentDate.getMonth() + 1;
		let newData = [data[0]];

		let startMonth1 =
			currentMonth < 4 ? 12 - (2 - currentMonth) : currentMonth - 2;
		let startMonth2 =
			currentMonth < 4 ? 12 - (1 - currentMonth) : currentMonth - 1;
		let startMonth3 = currentMonth < 4 ? 12 - currentMonth : currentMonth;
		if (finalDate === null) {
			finalDate = `${currentDate.getFullYear()}-${currentMonth
				.toString()
				.padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
		}

		const lastDayOfMonth1 = new Date(
			currentDate.getFullYear(),
			startMonth1,
			0
		).getDate();
		
		const lastDayOfMonth2 = new Date(
			currentDate.getFullYear(),
			startMonth2,
			0
		).getDate();

		let params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(JSON.stringify({
				dataAplicacao:  {
					lte: new Date(`${
						currentMonth < 4
							? currentDate.getFullYear() - 1
							: currentDate.getFullYear()
					}-${startMonth1.toString().padStart(2, "0")}-${lastDayOfMonth1
						.toString()
						.padStart(2, "0")}`),
					gte: new Date(startDate),
				},
			}))
		};
		const managementFirstMonth = await managementService.getByProperty(propertyId, params);

		params.filterValue = encodeURI(JSON.stringify({
			dataAplicacao:  {
				lte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth2.toString().padStart(2, "0")}-${lastDayOfMonth2
					.toString()
					.padStart(2, "0")}`),
				gte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth2.toString().padStart(2, "0")}-01`),
			},
		}));
		const managementSecondMonth = await managementService.getByProperty(propertyId, params);
		
		params.filterValue = encodeURI(JSON.stringify({
			dataAplicacao:  {
				lte: new Date(finalDate),
				gte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth3.toString().padStart(2, "0")}-01`),
			},
		}));
		const managementThirdMonth = await managementService.getByProperty(propertyId, params);

		params.filterValue = encodeURI(JSON.stringify({
			dataColheita:  {
				lte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth1.toString().padStart(2, "0")}-${lastDayOfMonth1
					.toString()
					.padStart(2, "0")}`),
				gte: new Date(startDate)
			},
			pendente: false
		}));
		let harvestsFirstMonth = await harvestService.getByProperty(propertyId, params);
		harvestsFirstMonth = harvestsFirstMonth.harvests;

		params.filterValue = encodeURI(JSON.stringify({
			dataColheita:  {
				lte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth2.toString().padStart(2, "0")}-${lastDayOfMonth2
					.toString()
					.padStart(2, "0")}`),
				gte: new Date(
					`${
						currentMonth < 4
							? currentDate.getFullYear() - 1
							: currentDate.getFullYear()
					}-${startMonth2.toString().padStart(2, "0")}-01`)
			},
			pendente: false
		}));
		let harvestsSecondMonth = await harvestService.getByProperty(propertyId, params);
		harvestsSecondMonth = harvestsSecondMonth.harvests;

		params.filterValue = encodeURI(JSON.stringify({
			dataColheita:  {
				lte: new Date(finalDate),
				gte: new Date(`${
					currentMonth < 4
						? currentDate.getFullYear() - 1
						: currentDate.getFullYear()
				}-${startMonth3.toString().padStart(2, "0")}-01`),
			},
			pendente: false
		}));
		let harvestsThirdMonth = await harvestService.getByProperty(propertyId, params);
		harvestsThirdMonth = harvestsThirdMonth.harvests;
		
		let serviceQuantity1 = 0;
		let inputQuantity1 = 0;
		for (let i = 0; i < managementFirstMonth.length; i++) {
			const service = managementFirstMonth[i].propriedadeServico;
			const managementInputs = managementFirstMonth[i].propriedadeInsumos;
			if (service !== null && service !== undefined) serviceQuantity1++;
			if (managementInputs && managementInputs.length > 0) inputQuantity1 += managementInputs.length;
		}

		let serviceQuantity2 = 0;
		let insumoQuantity2 = 0;
		for (let i = 0; i < managementSecondMonth.length; i++) {
			const service = managementSecondMonth[i].propriedadeServico;
			const managementInputs = managementSecondMonth[i].propriedadeInsumos;
			if (service !== null && service !== undefined) serviceQuantity2++;
			if (managementInputs && managementInputs.length > 0) insumoQuantity2 += managementInputs.length;
		}

		let serviceQuantity3 = 0;
		let insumoQuantity3 = 0;
		for (let i = 0; i < managementThirdMonth.length; i++) {
			const managementInputs = await managementThirdMonth[i].propriedadeInsumos;
			const service = await managementThirdMonth[i].propriedadeServico;
			if (service !== null && service !== undefined) serviceQuantity3++;
			if (managementInputs && managementInputs.length > 0) insumoQuantity3 += managementInputs.length;
		}

		newData.push([
			`${startMonth1.toString().padStart(2, "0")}/${
				currentMonth < 4 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
			}`,
			serviceQuantity1,
			inputQuantity1,
			harvestsFirstMonth.length,
		]);
		newData.push([
			`${startMonth2.toString().padStart(2, "0")}/${
				currentMonth < 4 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
			}`,
			serviceQuantity2,
			insumoQuantity2,
			harvestsSecondMonth.length,
		]);
		newData.push([
			`${currentMonth.toString().padStart(2, "0")}/${currentDate.getFullYear()}`,
			serviceQuantity3,
			insumoQuantity3,
			harvestsThirdMonth.length,
		]);

		if (newData.length === 1) {
			newData.push(["APLICAÇÃO DE SERVIÇO", 0]);
			newData.push(["APLICAÇÃO DE INSUMO", 0]);
			newData.push(["COLHEITA", 0]);
		}
		setData(newData);
		setIsLoading(false);
	};

	/* const getContractLicense = async () => {
		const companyId = localStorage.getItem("company");
		const company = await API.graphql(
			graphqlOperation(queries.getEmpresa, {id: companyId})
		);
		const licensa = await company.data.getEmpresa.LicensaEmpresa;
		//setLicense(licensa.contrato_assinado);
	}; */

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<>
				{/* license !== true ? <ContractModal/> : <></> */}
				<Box sx={{ display: "flex" }}>
					<TopMenu open={["home", "home"]} />
					<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
						<Box sx={{ width: "100%", px: 3 }}>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid
									item
									xs={12}
									sm={12}
									md={7}
									sx={{
										display: "flex",
										alignItems: "stretch",
										// height: "90vh",
									}}
								>
									<Item>
										
										<Grid item xs={12} sm={12} md={12}>
											<HomeCalender />
										</Grid>
										{/* <Grid item xs={12} sm={12} md={12}>
												<Chart data={data} options={options} chartType="ColumnChart" />
											</Grid> */}
									
									</Item>
								</Grid>
								<Grid item
									xs={12}
									sm={12}
									md={5}
									sx={{
										display: "flex",
										alignItems: "stretch",
										height: "100%",
									}}>
									<Grid container rowSpacing={1} columnSpacing={0} sx={{
										width: "100%",
										height: "100%",
									}}>
										<Grid item
											xs={12}
											sm={12}
											md={12}
											sx={{
												display: "flex",
												alignItems: "stretch",
												minHeight: "40vh",
												// height: "100%",
											}}>
											<Chart data={data} options={options} chartType="ColumnChart" />
										</Grid>
										<Grid item xs={12} sm={12} md={12} s>
											<Item sx={{height:"100%"}}>
												<Climate />
											</Item>
										</Grid>
									
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</>
			
		);
}

DrawerAppBar.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default DrawerAppBar;
