import React, { useEffect, useState } from "react";
import * as Button from "../../components/Buttons";
import InputData from "../../components/InputData";
import SearchHeader from "../../components/SearchHeader";
import InputSearch from "../../components/SearchHeader/inputSeach";
import HarvestDemand from "../../components/TableHarvest/TableHarvestDemand";
import HarvestRequested from "../../components/TableHarvest/TableHarvestRequested";
import HarvestHarvested from "../../components/TableHarvest/TableHavestHavested";
import TopMenu from "../../components/TopMenu";

//import css
import "../CommonStyle/style.css";
import styles from "./Harvest.module.css";

//tabs table imports
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import * as locales from "@mui/material/locale";

//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import HarvestForecast from "../../components/TableHarvest/TableHarvestForecast";
import { HarvestService } from "../../service/harvest_service";
import Loading from "../../components/Loading";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",

	p: 0,
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "100%" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Harvest() {
	const [search, setSearch] = useState(""); //search
	const [value, setValue] = React.useState(0);
	
	const [selectedDate, setSelectedDate] = useState("");
	const [isAdmin, setIsAdmin] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const harvestService = new HarvestService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		setIsAdmin(user.isAdmin);
		setIsLoading(false);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function handleClick(e) {
		setSearch(e.target.value); //seta o valor do campo de busca
	}

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const handleDelete = async (id) => {
		await harvestService.changeVisibility(id);
		initData();
	};

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date).toLocaleDateString("pt-BR"));
	};
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<>
				<TopMenu open={["launch", "harvest"]} help="harvest" />
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<SearchHeader
										title="Colheitas"
										href="/colheita/cadastroColheita"
										textButton="Nova Colheita "
										extraButton={
											<Button.GenericButtonGreen
												href="/colheita/previssaoColheita"
												text={"Previsao de Colheita"}
											/>
										}
									>
										<InputSearch func={handleClick} />
									</SearchHeader>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "#fff",
										border: "none",
										boxShadow: "none",
										pt: 3,
										p: 1,
										m: 0,
									}}
								>
									<ThemeProvider theme={themeWithLocale}>
										<Grid
											container
											rowSpacing={0}
											columnSpacing={{ xs: 0, sm: 2, md: 3 }}
											sx={{bgColor:"white"}}
										>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<Tabs
														value={value}
														onChange={handleChange}
														textColor="white"

														// className="tabs"
													>
														<Tab
															className={
																value === 0
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Demanda"
															{...a11yProps(0)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 1
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Realizadas "
															{...a11yProps(1)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 2
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Solicitadas "
															{...a11yProps(2)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 3
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Previsões"
															{...a11yProps(3)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
													</Tabs>												
												</Item>
												
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													{isAdmin === true && <div className={styles.btnGoto}>
														<div className={styles.btnContainer}>
															<InputData placeholder="Data" onDateChange={handleDateChange} value={selectedDate} classN={styles.btnContainerInputDate} />											
															<a href={`pdf/?pag=Colheita&startDate=${selectedDate}`} className={styles.btnGoToPdf}>Demanda para Colheita</a>
														</div>
													</div>}
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={0}
													>
														<HarvestDemand
															search={search}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={1}
													>
														<HarvestHarvested
															search={search}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={2}
													>
														<HarvestRequested
															search={search}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={3}
													>
														<HarvestForecast
															search={search}
															handleDelete={handleDelete}
														/>
													</TabPanel>
												</Item>
											</Grid>
										</Grid>
									</ThemeProvider>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</>
		);
}

export default Harvest;
